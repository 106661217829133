:root {
  --color-header: #0B3C5D;
  --color-navbar: #F7B733;
  --color-button: #E9E9E9;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Josefin Slab";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}

.App {
  margin-top: 15px;
}

.App .navbar-brand {
	background-color: var(--color-navbar);
}

.App .navbar-default {
  font-family: "Josefin Slab";
  font-size: large;
  font-weight: bold;
  background-color: var(--color-navbar);
}

.LoaderButton .spinning.glyphicon {
  margin-right: 7px;
  top: 2px;
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
}
@-webkit-keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}
@keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}
.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home .lander h1 {
  font-family: "Special Elite";
  color: var(--color-header);
}

.Home .lander p {
  font-family: "Josefin Slab";
  font-weight: bold;
  font-size: large;
  color: var(--color-header);

}

.Home .notes h4 {
  font-family: "Josefin Slab";
  font-weight: bold;
  margin-left: 15px;
  margin-top: 50px;
  margin-bottom: 20px;
  color: var(--color-header);
  text-align: center;
}

.Home .notes .list-group {
	font-size: medium;
	font-weight: bold;
  font-family: "Josefin Slab";
  color: var(--color-header);
}

.Home .notes .list-group-item {
	border: none;
  color: var(--color-header);
}

.Home .noteDetails {
	font-family: "Josefin Slab";
	margin-top: 5px;
	font-style: italic;
}

.Home .lander div {
  padding-top: 20px;
}
.Home .lander div a:first-child {
  margin-right: 20px;
}
.NotFound {
  padding-top: 100px;
  text-align: center;
}
@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
    font-family: "Josefin Slab";
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }

  .Login button {
  	background-color: var(--color-button)
  }
}
@media all and (min-width: 480px) {
  .Signup {
    padding: 60px 0;
    font-family: "Josefin Slab";
  }

  .Signup form {
    margin: 0 auto;
    max-width: 320px;
  }

  .Signup button {
    background-color: var(--color-button);
  }
}



.Signup form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
  color: #999;
}
.NewNote form {
  padding-bottom: 15px;
}

.NewNote form .noteInfo {
	margin-top: 20px;
	margin-bottom: 0px;
}

.NewNote form .noteName textarea {
	margin-left: -10px;
	font-family: "Josefin Slab";
	font-weight: bold;
	text-align: left;
	height: 25px;
	outline: none;
  	border: none;
  	box-shadow: none;
  	color: var(--color-header);
}

.NewNote form .noteDetails textarea {
	margin-left: -20px;
	font-family: "Josefin Slab";
	font-weight: bold;
	text-align: right;
	font-size: small;
	height: 22px;
	outline: none;
  	border: none;
  	box-shadow: none;
}

.NewNote form .noteContent textarea {
	margin-top: 45px;
  	margin-bottom: 50px;
	height: 350px;
  	font-size: small;
  	font-family: "Special Elite";
  	background-attachment: local;
  	background-image: -webkit-repeating-linear-gradient(white 0px, white 34px, gray 35px);
  	background-image: repeating-linear-gradient(white 0px, white 34px, gray 35px);
  	line-height: 35px;
  	outline: none;
  	border: none;
  	box-shadow: none;
  	color: var(--color-header);
}

.NewNote form .noteNameOnly textarea {
	margin-top: 50px;
	text-align: center;
	font-family: "Josefin Slab";
	font-weight: bold;
	height: 25px;
	outline: none;
  	border: none;
  	box-shadow: none;
  	color: var(--color-header);
}

.NewNote button {
	font-family: "Josefin Slab";
	font-size: medium;
	font-weight: bold;
	background-color: var(--color-button);
}
.Notes form {
  padding-bottom: 15px;
}

.Notes form .notesContent textarea {
	margin-top: 50px;
  	margin-bottom: 50px;
  	height: 350px;
  	font-size: small;
  	font-family: "Special Elite";
  	background-attachment: local;
  	background-image: -webkit-repeating-linear-gradient(white 0px, white 34px, gray 35px);
  	background-image: repeating-linear-gradient(white 0px, white 34px, gray 35px);
  	line-height: 35px;
  	outline: none;
  	border: none;
  	box-shadow: none;
  	color: var(--color-header);
}

.Notes .show-grid {
	margin-top: 20px;
	margin-bottom: 10px;
	font-family: "Josefin Slab";
	font-weight: bold;
	font-size: medium;
}

.Notes .show-grid h4 {
	font-size: medium;
	font-weight: bold;
	text-align: left;
}

.Notes .show-grid h5 {
	text-align: right;
}

.Notes h4 {
	margin-top: 50px;
	margin-bottom: 20px;
	font-family: "Josefin Slab";
	font-weight: bold;
	font-size: medium;
	text-align: center;
	color: var(--color-header);
}

.Notes button {
	font-family: "Josefin Slab";
	font-size: medium;
	font-weight: bold;
	background-color: var(--color-button);
}
