.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home .lander h1 {
  font-family: "Special Elite";
  color: var(--color-header);
}

.Home .lander p {
  font-family: "Josefin Slab";
  font-weight: bold;
  font-size: large;
  color: var(--color-header);

}

.Home .notes h4 {
  font-family: "Josefin Slab";
  font-weight: bold;
  margin-left: 15px;
  margin-top: 50px;
  margin-bottom: 20px;
  color: var(--color-header);
  text-align: center;
}

.Home .notes .list-group {
	font-size: medium;
	font-weight: bold;
  font-family: "Josefin Slab";
  color: var(--color-header);
}

.Home .notes .list-group-item {
	border: none;
  color: var(--color-header);
}

.Home .noteDetails {
	font-family: "Josefin Slab";
	margin-top: 5px;
	font-style: italic;
}

.Home .lander div {
  padding-top: 20px;
}
.Home .lander div a:first-child {
  margin-right: 20px;
}