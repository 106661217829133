@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
    font-family: "Josefin Slab";
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }

  .Login button {
  	background-color: var(--color-button)
  }
}