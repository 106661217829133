.App {
  margin-top: 15px;
}

.App .navbar-brand {
	background-color: var(--color-navbar);
}

.App .navbar-default {
  font-family: "Josefin Slab";
  font-size: large;
  font-weight: bold;
  background-color: var(--color-navbar);
}
