.NewNote form {
  padding-bottom: 15px;
}

.NewNote form .noteInfo {
	margin-top: 20px;
	margin-bottom: 0px;
}

.NewNote form .noteName textarea {
	margin-left: -10px;
	font-family: "Josefin Slab";
	font-weight: bold;
	text-align: left;
	height: 25px;
	outline: none;
  	border: none;
  	-webkit-box-shadow: none;
  	-moz-box-shadow: none;
  	box-shadow: none;
  	color: var(--color-header);
}

.NewNote form .noteDetails textarea {
	margin-left: -20px;
	font-family: "Josefin Slab";
	font-weight: bold;
	text-align: right;
	font-size: small;
	height: 22px;
	outline: none;
  	border: none;
  	-webkit-box-shadow: none;
  	-moz-box-shadow: none;
  	box-shadow: none;
}

.NewNote form .noteContent textarea {
	margin-top: 45px;
  	margin-bottom: 50px;
	height: 350px;
  	font-size: small;
  	font-family: "Special Elite";
  	background-attachment: local;
  	background-image: repeating-linear-gradient(white 0px, white 34px, gray 35px);
  	line-height: 35px;
  	outline: none;
  	border: none;
  	-webkit-box-shadow: none;
  	-moz-box-shadow: none;
  	box-shadow: none;
  	color: var(--color-header);
}

.NewNote form .noteNameOnly textarea {
	margin-top: 50px;
	text-align: center;
	font-family: "Josefin Slab";
	font-weight: bold;
	height: 25px;
	outline: none;
  	border: none;
  	-webkit-box-shadow: none;
  	-moz-box-shadow: none;
  	box-shadow: none;
  	color: var(--color-header);
}

.NewNote button {
	font-family: "Josefin Slab";
	font-size: medium;
	font-weight: bold;
	background-color: var(--color-button);
}