.Notes form {
  padding-bottom: 15px;
}

.Notes form .notesContent textarea {
	margin-top: 50px;
  	margin-bottom: 50px;
  	height: 350px;
  	font-size: small;
  	font-family: "Special Elite";
  	background-attachment: local;
  	background-image: repeating-linear-gradient(white 0px, white 34px, gray 35px);
  	line-height: 35px;
  	outline: none;
  	border: none;
  	-webkit-box-shadow: none;
  	-moz-box-shadow: none;
  	box-shadow: none;
  	color: var(--color-header);
}

.Notes .show-grid {
	margin-top: 20px;
	margin-bottom: 10px;
	font-family: "Josefin Slab";
	font-weight: bold;
	font-size: medium;
}

.Notes .show-grid h4 {
	font-size: medium;
	font-weight: bold;
	text-align: left;
}

.Notes .show-grid h5 {
	text-align: right;
}

.Notes h4 {
	margin-top: 50px;
	margin-bottom: 20px;
	font-family: "Josefin Slab";
	font-weight: bold;
	font-size: medium;
	text-align: center;
	color: var(--color-header);
}

.Notes button {
	font-family: "Josefin Slab";
	font-size: medium;
	font-weight: bold;
	background-color: var(--color-button);
}